<template>
<div class="row card p-0 mt-4">
  <div class="col-12 p-3">
    <p class="title-card">วันที่ไม่มีการผลิต/ ไม่ Full Load</p>
  </div>
  <div class="col-12 p-0">
    <table>
      <tr>
        <td class="font-weight-bold" colspan="2">Day</td>
        <td class="font-weight-bold">Status</td>
      </tr>
      <tr>
        <td class="weather"><img class="w-100" src="/images/icon/weather-drizzle.png" alt=""></td>
        <td>06-01-2021</td>
        <td>ไม่ Full Load</td>
      </tr>
      <tr>
        <td class="weather"><img class="w-100" src="/images/icon/weather-partly.png" alt=""></td>
        <td>06-01-2021</td>
        <td>ไม่ Full Load</td>
      </tr>
      <tr>
        <td class="weather"><img class="w-100" src="/images/icon/weather-sunny.png" alt=""></td>
        <td>06-01-2021</td>
        <td>ไม่ Full Load</td>
      </tr>
  
    </table>
  </div>
</div>

</template> 

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';

export default {
  name: 'NoFullLoad',
  components: {
  },
  data: function () {
    return {
        listData:''
    }
  },
  mounted() {
    //  this.getlistData()
  },
  methods: {
    async getlistData(){
      try {
        let data = {
          SIDE_ID:''
        }
        let getAPI = await serviceAPI.call_API('get','dashboard/GetMainDashboard',data,'auth');
        this.listData = getAPI.data.data[0]
        // GASCO2: "1277.27"
        // LOCATION: "53/1/3 Moo 7 Suk Samat Road ,Tambon Na Pa Ampor Mueang Chonburi ,Changwa Thailand 20000"
        // PEAKPOW: "9.90"
        // PLANTNAME: "AJAWIT KALAWANTAVANICH"
        // TREES: "38.12258970"
        // UPD: "20210715 20:18"

      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    }
  },
  computed: {
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
table {
  width: 100% ;
  border-collapse: collapse !important;
  
}
table, td, th {
  border: 1px solid #F1F2F6;
  // border: 2px #F1F2F6 solid !important;
  text-align: center;
}
td{
  color: #5A5F7D !important;
  padding: 5px 0px !important;
  font-size: 0.9rem !important;
}
.weather{
  padding: 7px !important;
  width: 40px;
}
</style>
