<template>
<div class="row mt-2">
    <div class="col-12 ">
        <CardIcon :name="t('sizeinstall',{},{locale:getLocal})" :val="listData.PEAKPOW?listData.PEAKPOW:'-'" unit="" image="/images/icon/icon-solar.png" />
        <CardIcon :name="t('lttr',{},{locale:getLocal})" :val="listData.TREES?listData.TREES:'-'" :unit="t('trees',{},{locale:getLocal})" image="/images/icon/icon-tree.png" />
        <CardIcon :ltco2r="t('sizeinstall',{},{locale:getLocal})" :val="listData.GASCO2?listData.GASCO2:'-'" :unit="t('tons',{},{locale:getLocal})" image="/images/icon/icon-co2.png" />
        <!-- <CardIcon name="ครั้งที่ทำคว่มสะอาด / ทั้งหมด" :val="listData.GASCO2?listData.GASCO2:'-'" unit="Tons" image="/images/icon/icon-clean.png" /> -->

        <!-- <div class="row card">
          <div class="col-12 p-3 ">      
            <div class="d-flex plant-card justify-content-start">
              <div>
                <img class="img-plant p-0 m-0" src="/images/icon/icon-clean.png" alt="img-plant" >
              </div>
              <div class="text-plant">
                <p v-if="listData !=''" class="text-val">{{listData.PEAKPOW}} </p>
                <p v-else class="text-val">- </p>
                <p class="text-name">ครั้งที่ทำคว่มสะอาด / ทั้งหมด</p>
              </div>
            </div>
          </div>
        </div> -->
    
    </div>
</div>

</template> 

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import CardIcon from '@/components/partial/CardIcon.vue';
import { useI18n } from 'vue-i18n';
export default {
  name: 'Dashboard4Data',
  setup() {
      const {t, locale} = useI18n();
      return {t, locale};
  },
  components: {
    CardIcon
  },
  data: function () {
    return {
        listData:[]
    }
  },
  mounted() {
     this.getlistData()
  },
  methods: {
    async getlistData(){
     try {
        let getTEXTSRC ='DAY';
        if(this.GET_dateType=='DAY'){
          getTEXTSRC = this.GET_dateToday;
        }
        if(this.GET_dateType=='MONTH'){
          getTEXTSRC = this.GET_dateMonth;
        }
        if(this.GET_dateType=='YEAR'){
          getTEXTSRC = this.GET_dateYear;
        }
        let data = {
          INVERTER_ID:this.GET_inverterId,
          SIDE_ID:this.GET_plantId,
          CHARTTYPE: this.GET_dateType,
          TEXTSRC:getTEXTSRC
        }
        let getAPI = await serviceAPI.call_API('get','dashboard/GetDashboardDetail',data,'auth');
        this.listData = getAPI.data.data[0]
       

      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    }
  },
  computed: {
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
    GET_plantId:function(){
      return this.$store.getters['localstorage/GET_plantId']
    },
    GET_inverterId:function(){
      return this.$store.getters['localstorage/GET_inverterId']
    },
    GET_dateToday:function(){
      return this.$store.getters['localstorage/GET_dateToday']
    },
    GET_dateMonth:function(){
      return this.$store.getters['localstorage/GET_dateMonth']
    },
    GET_dateYear:function(){
      return this.$store.getters['localstorage/GET_dateYear']
    },
    GET_dateType:function(){
      return this.$store.getters['localstorage/GET_dateType']
    },
    GET_socketSetReload:function(){
      return this.$store.getters['service/GET_socketSetReload']
    }
  },
  watch: {
    GET_plantId: function (val) {
      this.getlistData()
    },
    GET_dateToday: function (val) {
      if(this.GET_dateType=='DAY'){
        this.getlistData()
      }
    },
    GET_dateMonth: function (val) {
      if(this.GET_dateType=='MONTH'){
        this.getlistData()
      }
    },
    GET_dateYear: function (val) {
      if(this.GET_dateType=='YEAR'){
        this.getlistData()
      }
    },
    GET_dateType: function (val) {
        this.getlistData()
    },
    GET_socketSetReload: function (val) {
        this.getlistData()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
