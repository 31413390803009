<template>
  <div class="row mt-4">
      <div class="col-12 ">
           <CardIcon :name="t('sizeinstall',{},{locale:getLocal})" :val="listData.PEAKPOW?listData.PEAKPOW:'-'" unit="" image="/images/icon/icon-solar.png" />
           <CardIcon :name="` ${t('sumprod',{},{locale:getLocal})} ${GET_dateMonth}`"  :val="listData.SUMPRODUCTION?listData.SUMPRODUCTION:'-'" unit="" image="/images/icon/icon-elec.png" />
            
      </div>
  </div>
</template> 

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import CardIcon from '@/components/partial/CardIcon.vue';
import { useI18n } from 'vue-i18n';
export default {
  name: 'MonthlyDetail',
  setup() {
      const {t, locale} = useI18n();
      return {t, locale};
  },
  components: {
    CardIcon
  },
  data: function () {
    return {
        listData:[]
        
    }
  },
  mounted() {
     this.getlistData()
  },
  methods: {
    async getlistData(){
      try {
         let getTEXTSRC ='MONTH';
        
        if(this.GET_dateType=='MONTH'){
          getTEXTSRC = this.GET_dateMonth;
          }
        let data = {
          INVERTER_ID:this.GET_inverterId,
          SIDE_ID:this.GET_plantId,
          CHARTTYPE: this.GET_dateType,
          TEXTSRC:getTEXTSRC
        }
        let getAPI = await serviceAPI.call_API('get','dashboard/GetDashboardDetail',data,'auth');
        this.listData = getAPI.data.data[0]
       

      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    }
  },
  computed: {
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
    GET_plantId:function(){
      return this.$store.getters['localstorage/GET_plantId']
    },
    GET_inverterId:function(){
      return this.$store.getters['localstorage/GET_inverterId']
    },
    GET_dateMonth:function(){
      return this.$store.getters['localstorage/GET_dateMonth']
    },
    GET_dateType:function(){
      return this.$store.getters['localstorage/GET_dateType']
    },
    GET_socketSetReload:function(){
      return this.$store.getters['service/GET_socketSetReload']
    }
  },
  watch: {
    GET_plantId: function (val) {
      this.getlistData()
    },
    GET_dateMonth: function (val) {
      if(this.GET_dateType=='MONTH'){
        this.getlistData()
      }
    },
    GET_dateType: function (val) {
        this.getlistData()
    },
    GET_socketSetReload: function (val) {
        this.getlistData()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
