<template>
<div class="row card p-1 mt-4">
    <div class="col-12">
      <div class="row ">
        <div class="col-12 p-3">
          <p class="title-card">ตัวอย่างกราฟที่ไม่นำมาคิด</p>
        </div>
        <div class="col-12">
          <hr class="m-0">
        </div>
        <div class="col-12">
          <div class="row my-3" >
            <div v-for="(e,index) in listData" :key="index" class="col-6 col-md-2 ">
              <img class="w-100" src="/images/icon/dashnoprocess.png" alt="">
            </div>
            
          </div>
        </div>
      </div>
    </div>
</div>

</template> 

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';

export default {
  name: 'GraphsNotCal',
  components: {
  },
  data: function () {
    return {
      listData:[
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },
        {
          img:'/images/icon/dashnoprocess.png'
        },

      ]
      
    }
  },
  mounted() {
    //  this.getlistData()
  },
  methods: {
    setChart(){

    },
    async getlistData(){
      try {
        let data = {
          SIDE_ID:'0'
        }
        let getAPI = await serviceAPI.call_API('get','dashboard/GetMainDashboard',data,'auth');
        this.listData = getAPI.data.data[0]

      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    }
  },
  computed: {
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
    
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// .card-progress{
//   background: #fff;
//   box-shadow: 0 10px 30px rgb(146 153 184 / 15%);
//   border-radius: 10px;
//   padding: 25px;
// }
.card-overview-progress{
  margin-top:0px !important;
  min-height: 50px !important;
  max-width: 100% !important;
  .card-body{
    margin-top:0px !important;
    padding:0px;
  }
}

.card-progress__summerys{
  margin-bottom: 10px;
}
.min-hight{
  min-height: 300px;
}
</style>
