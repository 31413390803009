<template>
        <div :class="`${($store.getters['display/getAside'])?'contents expanded':'contents'}`">

            <div class="container-fluid">
                <div class="social-dash-wrap">
                    <div class="row">
                        <div class="col-12">
                            <BreadCrumbDate namePage="Dashboard" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-3">
                            <CompanyDetail />
                            <SelectPlant />
                            <DashboardDetail />

                        </div>
                        <div class="col-12 col-md-9">
                            <DashboardEnergy />
                            <EnergyFlow />
                            <PowerEnergy />
                            <div class="row mt-4">
                                <div class="col-12 col-md-6">
                                    <ComparePvsystChart/>
                                </div>
                                <div class="col-12 col-md-6">
                                    <PvsystTable/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
</template>

<script>
// @ is an alias to /src
import BreadCrumbDate from '@/components/main/BreadCrumbDate.vue';
import CompanyDetail from '@/components/main/CompanyDetail.vue';
import SelectPlant from '@/components/main/SelectPlant.vue';
import DashboardDetail from '@/components/dashboard/DashboardDetail.vue';
import DashboardEnergy from '@/components/dashboard/DashboardEnergy.vue';
import EnergyFlow from '@/components/dashboard/EnergyFlow.vue';
import PowerEnergy from '@/components/dashboard/PowerEnergy.vue';
import ComparePvsystChart from '@/components/dashboard/ComparePvsystChart.vue';
import PvsystTable from '@/components/dashboard/PvsystTable.vue';
// import { lightFormat } from 'date-fns'
export default {
  name: 'Dashboard',
  components: {
    BreadCrumbDate,
    CompanyDetail,
    SelectPlant,
    DashboardDetail,
    DashboardEnergy,
    EnergyFlow,
    PowerEnergy,
    ComparePvsystChart,
    PvsystTable
  },
  data: function () {
    return {

      }
    },
  mounted() {
      this.$store.dispatch('display/SET_pageName',"Dashboard")
  },
  methods: { 
  }
}

</script>